import React, { useContext } from "react";
import { Context } from "redux/Store";
import { getUrlFromSlug } from "libs/services/utils";
import Layout from "layout";
import { Row, Col } from "react-grid-system";
import Seo from "common/seo";
import ProductDetails from "common/product-details";
import AccordionContent from "common/accordion";
import { TypeA, TypeC } from "common/callouts";
import useGoToAnchor from "libs/hooks/useGoToAnchor";

import frontImage from "images/sun/sunproducts/advanced-hydration-product-front.webp";
import backImage from "images/sun/sunproducts/advanced-hydration-product-back.webp";
import frontZoomImage from "images/sun/sunproducts/advanced-hydration-product-front-zoom.webp";
import backZoomImage from "images/sun/sunproducts/advanced-hydration-product-back-zoom.webp";

import img1 from "images/sun/sunproducts/advanced-hydration-hydration.webp";
import img2 from "images/sun/sunproducts/advanced-hydration-non-therapetuic-feedbackb.webp";
import img3 from "images/sun/sunproducts/advanced-hydration-therapetuic-feedbackb.webp";
import img4 from "images/sun/sunproducts/advanced-hydration_FSTI-IIIb.webp";
import img5 from "images/sun/sunproducts/advanced-hydration_FSTIV-VIb.webp";

import img8 from "images/callouts/cout-patient-resource.webp";
import img9 from "images/callouts/cout-footer-dry-skin-therapy.webp";
import img10 from "images/callouts/cout-footer-5aox.webp";
import img11 from "images/callouts/cout-footer-journal.webp";

import "./advancedhydration.scss";

/* function BoldText({children}) {
  return <span style={{fontWeight: 'bold'}}>{children}</span>;
} */

function AdvacnedHydrationPage({ pageContext }) {
  const { state, dispatch } = useContext(Context);

  useGoToAnchor();

  return (
    <Layout
      pageClass="page-sun-eucerinsunproducts-advanced-hydration page-level-3"
      breadcrumbData={pageContext.breadcrumb}
    >
      <Seo
        pageTitle="Eucerin Sun Advanced Hydration"
        pageDescription="Learn about Eucerin Sun Advaned Hydration for dry skin"
      />
      <div className="inner-body-content">
        <div className="top-content-level-3">
          <section className="inner-centered-container">
            <ProductDetails
              categoryBgColor="#E07810"
              categoryName="SUN CARE"
              categoryFontColor="#ffffff"
              productName="Eucerin<sup>®</sup> Sun Advanced Hydration"
              productSlider={[frontImage]}
              zoomImages={[frontZoomImage]}
            >
              <div className="functions">
                <p className="font-semi-bold mango-tango-text sub-title">
                  Sun protection, skin hydration, free radical defense
                </p>
                <p>
                  <span className="font-semi-bold">
                    Eucerin Sun Advanced Hydration
                  </span>{" "}
                  is formulated with hyaluronic acid and other humectants to
                  hydrate the skin and replenish the skin's natural moisture
                  with daily use.
                </p>
                <ul className="mango-tango-bullets">
                  <li>
                    <span>Clinically shown to hydrate up to 8 hours</span>
                  </li>
                  <li>
                    <span>
                      <span className="font-semi-bold">
                        {" "}
                        Formulation helps defend against free radicals
                      </span>
                    </span>
                  </li>
                  <li>
                    <span>
                      Silky sunscreen blends seamlessly with all skin tones
                    </span>
                  </li>
                </ul>
              </div>
              <div className="key-ingredients">
                <p className="font-semi-bold mango-tango-text sub-title">
                  Active ingredients
                </p>
                <p className="no-mb font-semi-bold">
                  Avobenzone 3% <br /> Homosalate 7% (cream), 9% (spray) <br />{" "}
                  Octisalate 4.5% <br /> Octocrylene 8% (cream), 9% (spray)
                </p>
                <ul className="mango-tango-bullets">
                  <li>
                    <span>UVA/UVB broad-spectrum protection</span>
                  </li>
                </ul>
              </div>
              <div className="key-ingredients no-brd">
                <p className="font-semi-bold mango-tango-text sub-title">
                  Key ingredients
                </p>
                <p className="no-mb font-semi-bold">
                  5 AOX Shield<sup>&trade;</sup> helps to:
                </p>
                <ul className="mango-tango-bullets">
                  <li>
                    <span>Neutralize free radicals</span>
                  </li>
                  <li>
                    <span>Guard against free radical formation</span>
                  </li>
                  <li>
                    <span>Defend against natural antioxidant breakdown</span>
                  </li>
                </ul>
                <p className="no-mb font-semi-bold">
                  Hyaluronic acid + humectants
                </p>
                <ul className="mango-tango-bullets">
                  <li>
                    <span>
                      With continuous use, helps prevent skin dehydration and
                      helps to restore its natural moisture
                    </span>
                  </li>
                </ul>
              </div>
            </ProductDetails>
            <Row>
              <Col xs={12}>
                <div className="features">
                  <p className="font-semi-bold mango-tango-text sub-title">
                    Formulated for dry skin
                  </p>
                  <ul className="checkmarks mango-tango">
                    <li>
                      <span>Hypoallergenic</span>
                    </li>
                    <li>
                      <span>Non-comedogenic</span>
                    </li>
                    <li>
                      <span>Ophthalmologist-tested</span>
                    </li>
                  </ul>
                  <ul className="checkmarks mango-tango">
                    <li>
                      <span>Fragrance-free</span>
                    </li>
                    <li>
                      <span>Octinoxate-free</span>
                    </li>
                    <li>
                      <span>Oxybenzone-free</span>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </section>
        </div>
        <div className="gradient-bg">
          <section className="inner-centered-container">
            <AccordionContent
              accordionHeading="CLINICAL RESULTS"
              productThemeColor="sun-mango-tango"
              id="clinicalresults"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={img1}
                    className="accordion-chart"
                    alt="Graph with hydration efficacy of 8-hour duration"
                  />
                  <p className="footnotes">
                    <span className="font-bold">Study design:</span> Single
                    application of product assessed over 8 hours among 41 female
                    subjects with mild to moderate dry skin. Assessment was
                    average of three corneometer measurements.
                  </p>
                  <p className="references-symbols double last">
                    **Statistically significant difference vs baseline;{" "}
                    <span className="font-italic">p</span>&lt;0.001.
                  </p>
                  <p className="references">
                    <span className="font-bold">Reference: 1.</span> Data on
                    file. Beiersdorf Inc.
                  </p>
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                    {" "}
                    Results are also <br className="show-desktop" /> applicable
                    to <br className="show-desktop" /> Eucerin<sup>®</sup> Sun
                    Body <br className="show-desktop" /> Lotion broad-
                    <br className="show-desktop" /> spectrum SPF 30
                    <br className="show-desktop" /> and Eucerin<sup>&reg;</sup>{" "}
                    Sun
                    <br className="show-desktop" /> Body Spray broad-<br className="show-desktop" />spectrum SPF 50
                  </div>
                </Col>
              </Row>
            </AccordionContent>

            <AccordionContent
              accordionHeading="NON-THERAPEUTIC USER <nobr>SURVEY RESULTS</nobr>"
              productThemeColor="sun-mango-tango"
              id="nontherapeuticuserresults"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={img2}
                    className="accordion-chart"
                    alt="Real-world patient feedback among non-therapeutic sunscreen users"
                  />
                  <p className="references">
                    <span className="font-bold">Reference: 1.</span> Data on
                    file. Beiersdorf Inc.
                  </p>
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                    Exceptional results <br className="show-desktop" /> for all
                    parameters
                  </div>
                </Col>
              </Row>
            </AccordionContent>

            <AccordionContent
              accordionHeading="THERAPEUTIC USER SURVEY RESULTS"
              productThemeColor="sun-mango-tango"
              id="therapeuticuserresults"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={img3}
                    className="accordion-chart"
                    alt="Real-world patient feedback among therapeutic sunscreen users"
                  />
                  <p className="references">
                    <span className="font-bold">Reference: 1.</span> Data on
                    file. Beiersdorf Inc.
                  </p>
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                    Great results from <br className="show-desktop" /> users of
                    leading <br className="show-desktop" /> brands
                  </div>
                </Col>
              </Row>
            </AccordionContent>

            <AccordionContent
              accordionHeading="FST I-III SURVEY RESULTS"
              productThemeColor="sun-mango-tango"
              id="FSTItoIIIresults"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={img4}
                    className="accordion-chart"
                    alt="Real-world patient feedback among users, FST I-III"
                  />
                  <p className="references">
                    <span className="font-bold">Reference: 1.</span> Data on
                    file. Beiersdorf Inc.
                  </p>
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                    Exceptional results <br className="show-desktop" /> for all
                    parameters
                  </div>
                </Col>
              </Row>
            </AccordionContent>

            <AccordionContent
              accordionHeading="FST IV-VI SURVEY RESULTS"
              productThemeColor="sun-mango-tango"
              id="FSTIVtoVIresults"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={img5}
                    className="accordion-chart"
                    alt="Real-world patient feedback among users, FST IV-VI"
                  />
                  <p className="references">
                    <span className="font-bold">Reference: 1.</span> Data on
                    file. Beiersdorf Inc.
                  </p>
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                    Exceptional results <br className="show-desktop" /> for all
                    parameters
                  </div>
                </Col>
              </Row>
            </AccordionContent>
          </section>
        </div>
        <section className="callouts-container gradient-bg section-support-resource orange">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={8} order={{ xs: 2, md: 1 }} className="">
                <TypeC
                  buttonText="DOWNLOAD HERE"
                  buttonUrl={getUrlFromSlug(
                    "eucerin-sun-patient-brochure",
                    state?.sanityData?.allResources
                  )}
                  className="center-v"
                  isExternalLink={true}
                  targetOpen="_blank"
                >
                  <p className="reg-text-big">
                    Support your recommendation <br className="show-desktop" />{" "}
                    with this resource
                  </p>
                  <p className="reg-text">
                    Provide your patients with sun protection tips and more.
                  </p>
                </TypeC>
              </Col>
              <Col sm={12} md={4} order={{ xs: 1, md: 2 }} className="">
                <img
                  src={img8}
                  alt="Everyone under the sun needs protection"
                  className="cout-product-img"
                />
              </Col>
            </Row>
          </div>
        </section>
        <section className="callouts-container gradient-bg blue-grad">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img9}
                  imgAlt="Hydration"
                  caption="Recommend daily hydration <br class='show-desktop' /> along with sun protection"
                  buttonText="Explore Body Care"
                  buttonUrl="/body"
                  imgStyles={{ height: 158 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img10}
                  imgAlt="5AOX Shield"
                  caption="Formulas to help defend against damage from oxidative stress"
                  buttonText="See the modes of defense"
                  buttonUrl="/sun/freeradicaldefense"
                  imgStyles={{ height: 165 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img11}
                  imgAlt="JAAD"
                  caption="Discover how perspectives in sun protection are evolving"
                  buttonText="VIEW JOURNAL ARTICLES"
                  buttonUrl="https://www.jaad.org/issue/S0190-9622(22)X0002-9"
                  imgStyles={{ height: 165 }}
                  isExternalLink={true}
                  targetOpen="_blank"
                />
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default AdvacnedHydrationPage;
